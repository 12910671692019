// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeSlider from './audio/VolumeSlider';
import BeachSound from './audio/BeachSound.js';
import RainSound from './audio/RainSound.js';
import byteDropletImage from './images/byte_droplet.png';

const AppBarTop = () => {
  const [playRainSound, setPlayRainSound] = useState(false);
  const [playBeachSound, setPlayBeachSound] = useState(false);
  const [volume, setVolume] = useState(0.5);

  const handleRainSoundToggle = () => {
    setPlayRainSound(!playRainSound);
    if (!playRainSound) {
      setPlayBeachSound(false);
    }
  };

  const handleBeachSoundToggle = () => {
    setPlayBeachSound(!playBeachSound);
    if (!playBeachSound) {
      setPlayRainSound(false);
    }
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        color: "white",


      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div>
          <img
            src={byteDropletImage}
            alt="Byte Droplet"
            style={{
              width: 40,
              height: 40,
              marginRight: 8,

            }}
          />

          <Typography
            variant="h1"
            sx={{
              textAlign: "left",
              color: "#cc00cc",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            BytesZilla
          </Typography>
        </div>

        <div>
          <IconButton
            onClick={handleRainSoundToggle}
            color="inherit"
            title={playRainSound ? "Stop Rain Sound" : "Play Rain Sound"}
          >
            {playRainSound ? (
              <VolumeOffIcon style={{ fontSize: 32, color: '#ff66b3' }} />
            ) : (
              <VolumeUpIcon style={{ fontSize: 32, color: '#ff0066' }} />
            )}
          </IconButton>

          <IconButton
            onClick={handleBeachSoundToggle}
            color="inherit"
            title={playBeachSound ? "Stop Beach Sound" : "Play Beach Sound"}
          >
            {playBeachSound ? (
              <VolumeOffIcon style={{ fontSize: 32, color: '#73264d' }} />
            ) : (
              <VolumeUpIcon style={{ fontSize: 32, color: 'blue' }} />
            )}
          </IconButton>

          <VolumeSlider value={volume} onChange={handleVolumeChange} />
        </div>
      </Toolbar>

      <BeachSound play={playBeachSound} volume={volume} />
      <RainSound play={playRainSound} volume={volume} />
    </AppBar>
  );
};

export default AppBarTop;
