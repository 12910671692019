import React, { useEffect, useState } from 'react';
import './App.css';
import Background from './images/wine.png';
import AppBarTop from './AppBarTop.js';
import AppBarBottom from './AppBarBottom.js';
import Splash from './Splash.js';
import { Box } from '@mui/material';
import YouTube from 'react-youtube';

const App = () => {
  const backgroundStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100dvh',
    width: '100%',
    position: 'relative',

  };

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '10px',
    textAlign: 'center',
     // Ensure the video box is rendered above the rain add zIndex: 1
  };

  const videoContainerStyle = {
    position: 'relative',
  };

  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const opts = {
    height: '315',
    width: '560',
    playerVars: {
      autoplay: isVideoPlaying ? 1 : 0,
      controls: 1,
    },
  };

  const onPlayVideo = () => {
    setVideoPlaying(true);
  };

  useEffect(() => {
    const rainContainer = document.querySelector('.rain-container');

    const background = [
      'linear-gradient(transparent, aqua)',
      'linear-gradient(transparent, red)',
      'linear-gradient(transparent, limegreen)',
      'linear-gradient(transparent, white)',
      'linear-gradient(transparent, yellow)',
    ];

    const amount = 100;

    for (let i = 0; i < amount; i++) {
      const drop = document.createElement('i');

      const raindropProperties = {
        width: Math.random() * 5 + 'px',
        positionX: Math.floor(Math.random() * window.innerWidth) + 'px',
        delay: Math.random() * -20 + 's',
        duration: Math.random() * 5 + 's',
        bg: background[Math.floor(Math.random() * background.length)],
        opacity: Math.random() + 0.2,
      };

      drop.style.width = raindropProperties.width;
      drop.style.left = raindropProperties.positionX;
      drop.style.animationDelay = raindropProperties.delay;
      drop.style.animationDuration = raindropProperties.duration;
      drop.style.background = raindropProperties.bg;
      drop.style.opacity = raindropProperties.opacity;

      rainContainer.appendChild(drop);
    }
  }, []);

  return (
    <>
      <Box sx={{ position: 'absolute', zIndex: 1 }}>
        <Splash />
      </Box>

  <div style={backgroundStyle}>
  <div className="rain-container"  />

  <AppBarTop />
  <AppBarBottom />

  <div style={boxStyle}>
    {/* Embedded YouTube Video */}
    <div style={videoContainerStyle}>
      <YouTube videoId="TUADs-CK7vI" opts={opts} onPlay={onPlayVideo} />
    </div>
  </div>
</div>

    </>
  );
};

export default App;
