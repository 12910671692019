// eslint-disable-next-line
import React, { useEffect, useRef } from 'react';
import beachSoundFile from './mancini.mp3';
import { Howl } from 'howler';

const BeachSound = ({ play, volume }) => {
  // Using useRef to persist the Howl instance without re-rendering
  const beachSound = useRef(null);

  // Initialize the Howl instance
  useEffect(() => {
    beachSound.current = new Howl({
      src: [beachSoundFile],
      loop: true,
      volume: volume,
    });

    return () => {
      if (beachSound.current) {
        beachSound.current.stop();
        beachSound.current = null;
      }
    };// eslint-disable-next-line
  }, []); // Empty dependency array to run only once on mount

  // Update the playing state
  useEffect(() => {
    if (play && beachSound.current) {
      beachSound.current.play();
    } else if (beachSound.current) {
      beachSound.current.stop();
    }
  }, [play]); // Dependency on play state

  // Update the volume
  useEffect(() => {
    if (beachSound.current) {
      beachSound.current.volume(volume);
    }
  }, [volume]); // Dependency on volume

  return null;
};

export default BeachSound;
