// eslint-disable-next-line
import React, { useEffect, useRef } from 'react';
import rainSoundFile from './rain.ogg';
import { Howl } from 'howler';

const RainSound = ({ play, volume }) => {
  // Using useRef to persist the Howl instance without re-rendering
  const rainSound = useRef(null);

  // Initialize the Howl instance
  useEffect(() => {
    rainSound.current = new Howl({
      src: [rainSoundFile],
      loop: true,
      volume: volume,
    });

    return () => {
      if (rainSound.current) {
        rainSound.current.stop();
        rainSound.current = null;
      }
    };// eslint-disable-next-line
  }, []); // Empty dependency array to run only once on mount

  // Update the playing state
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (play && rainSound.current) {
      rainSound.current.play();
    } else if (rainSound.current) {
      rainSound.current.stop();
    }
  }, [play]); // Dependency on play state

  // Update the volume
  useEffect(() => {
    if (rainSound.current) {
      rainSound.current.volume(volume);
    }
  }, [volume]); // Dependency on volume

  return null;
};

export default RainSound;
