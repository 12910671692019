// VolumeSlider.js
import React from 'react';
import { Slider } from '@mui/material';

const VolumeSlider = ({ value, onChange }) => {
  return (
    <Slider
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      aria-labelledby="continuous-slider"
      min={0}
      max={1}
      step={0.01}
    />
  );
};

export default VolumeSlider;
